import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import "../../styles/main.scss"

const Minecare = () => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 600)
  }, [])

  return loader ? (
    <div className="loader" />
  ) : (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <article>
        <div className="container">
          <h2>MINECARE - Mining Environmental and lifeCycle Assessment and Resource Evaluation</h2>
          <figure>
            <img
             src={"/minecare.png"}
            />
          </figure>
          <h3>I4-GREEN Project</h3>
          <p>
          <a className="a-blue" href="https://i3-i4green.eu/" target="_blank">I4-GREEN</a> drives and boosts green, circular, and social tech deployment through joint investments, and works to unleash the green innovation power of EU Interregional Ecosystems, with the goal of reducing EU dependence on imports of Critical Raw Materials and improving the performance of the mining companies in terms of sustainability.
          </p>
          <p>
          I4-GREEN stems from a S3 network and scales into a unique interregional ecosystem, a node for the green transformation of extractive industries and the emergence of an EU sustainable mining value chain.
          </p>
          <hr />
          <h3>MINECARE</h3>
          <p className="mt-2">
          MINECARE project will employ a digital LCA methodology, based on the LCInsight platform, to improve sustainable mining procedures. LCInsight enables the collection of data from specific activities and generates key sustainability indicators, utilizing its advanced interface and visualization capabilities.
          </p>
          <p>
          The aim of this project is to monitor the environment into the I4-GREEN Extremadura Pilot mine. These insights can be expanded to the European iron ore industry, helping to identify potential opportunities for enhancing environmental performance and achieving an optimal environmental footprint. This effort is fully aligned with the transformative actions of the European Green Deal.
          </p>
          <figure>
            <img
             src={"/minecare-arch.png"}
             className="mt-2"
            />
            <figcaption>Technological Architecture of LCInsight</figcaption>
          </figure>
          <h3>Expected Impact</h3>
          <p>
          The circular recovery of rare earth materials from the iron ore tailings process, along with the “cradle-to-gate” LCA approach advocated by the MINECARE project, can significantly contribute to achieving an optimal environmental footprint for the Pilot mine under the I4- GREEN initiative. This initiative focuses on key strategic domains such as digitalization, sustainable mobility and operation, green energy, water recycling, and circular recovery.
          </p>
          <p>
          The MINECARE project aims to become a European reference for best practices in sustainable mining and interregional innovation, pooling complementary capabiliAes.
          </p>
          <figure className="ue-horizon-logo">
              <img
             src={"/europe-funding.png"}
              />
            </figure>
        </div>
      </article>
    </Layout>
  )
}

export default Minecare;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`   